import { Component, OnInit, ViewChild,ElementRef,Injectable,AfterViewInit,ViewEncapsulation,Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import {ErrorStateMatcher} from '@angular/material/core';
import { FormControl,FormGroup, FormBuilder, Validators,FormGroupDirective, NgForm } from '@angular/forms';
import {MatDialog} from "@angular/material/dialog";
import {MatDialogConfig} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import { ApiService } from '../../services/api.service';
import { DialogComponent } from '../../dialog.component';
import { AdminDialogComponent } from '../../adminDialog.component';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-manageContents',
  templateUrl: './manageContents.component.html',
  styleUrls: ['./manageContents.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ManageContentsComponent implements OnInit,AfterViewInit{	
  contentDisplayedColumns: string[] = ['edit','contentName','REPORT_NAME','LINK_NAME','FILE_PATH','delete'];  
  contentColumns: any = [{'label':'Edit'},{'label':'Content Name'},{'label':'Report Name'},{'label':'Link Name'},{'label':'File Path'},{'label':'Remove'}];
  contentDataSource = new MatTableDataSource();
  sectionId;
  matcher = new MyErrorStateMatcher();
  contentpageLength: number;
  contentpageSize: number = 10;
  contentdataLength: number;
  contentList: any;
  @ViewChild(MatSort) contentsort: MatSort;
  @ViewChild('contentpaginator') contentpaginator: MatPaginator;
  
  constructor(public dialog: MatDialog,private ApiService: ApiService,private route: ActivatedRoute,
    private frmBuilder:FormBuilder, private router: Router) {
      this.sectionId = this.route.snapshot.params.sectionId;  
      this.getContent(this.sectionId);    
  }
  ngOnInit() {    
    this.contentDataSource.sort = this.contentsort;
  } 
  ngAfterViewInit() {	
  }
  getContent(sectionId) {
    this.ApiService.getContentList(sectionId).subscribe(
      data => {
        this.contentList = data.contents;
        this.contentDataSource.data = data.contents;					
        this.contentdataLength = data.contents.length;
        this.contentpageLength = Math.ceil(this.contentdataLength / this.contentpageSize);
        setTimeout(() => this.contentDataSource.paginator = this.contentpaginator);   
      },
			error => {
     });
  } 
  openAddContentDialog() {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "ADD CONTENT",
			title: 'ADD CONTENT',
      page:'add-content',
      sectionId:this.sectionId
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getContent(this.sectionId);
				}				
		}); 
  }
  editContentDialog(element){
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "EDIT CONTENT",
			title: 'EDIT CONTENT',
      page:'edit-content',
      sectionId:this.sectionId,
      obj:element
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getContent(this.sectionId);
				}				
		});
  }
  removeContentDialog(id) {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "REMOVE CONTENT",
			title: 'REMOVE CONTENT',
      page:'remove-content',
      contentId:id
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getContent(this.sectionId);
				}				
		}); 
  }
  back(type){
    if(type == 'admin') {
     this.router.navigate(['databook/admin',1010]);
    }
  } 
  
}
/** Error when invalid control is dirty, touched, or submitted.*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted; 
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}