import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import {MatDialog} from "@angular/material/dialog";
import {MatDialogConfig} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatSelect} from "@angular/material/select";
import { ApiService } from '../../services/api.service';
import { DialogComponent } from '../../dialog.component';
import { AdminDialogComponent } from '../../adminDialog.component';
import { environment } from '../../../environments/environment';
import { takeUntil, startWith, map, take, debounceTime, switchMap } from 'rxjs/operators';
import { ReplaySubject, Subject, Observable } from 'rxjs';
const manageProviders = 'databook/manageProviders';

@Component({
  selector: 'app-manageProviders',
  templateUrl: './manageProviders.component.html',
  styleUrls: ['./manageProviders.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ManageProvidersComponent implements OnInit, AfterViewInit {
  public datactrl: FormControl = new FormControl();
  public datafilterctrl: FormControl = new FormControl();
  columnsToDisplay = ['action', 'name', 'email', 'phone', 'entitydatacategory'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageLength: number;
  pageSize: number = 5;
  dataLength: number;
  dataCategoryColumns = ['action', 'status', 'entity', 'datacategory', 'ccemail'];
  existingDataCategory = new MatTableDataSource();
  @ViewChild(MatPaginator) existingDCpaginator: MatPaginator;
  existingDCpageLength: number;
  existingDCpageSize: number = 2;
  existingDCdataLength: number;
  providerId: number;
  dataProvidersForm: FormGroup;
  dataCategoryForm: FormGroup;
  adminList: any;
  entityList: any = 0;
  dataCategoryList: any;
  dataCategoryDropdownList: any;
  dataCategoryDropdown: any;
  successBlock: boolean = false;
  successMsg: string;
  errorBlock: boolean = false;
  errorMsg: string;
  dataProviderInfoBlock: boolean;
  existingDCEnable: boolean = true;
  dataProviderInfo: any;
  matcher = new MyErrorStateMatcher();
  filteredOptions: Observable<any[]>;
  entitydatacategory : any;
  dataarray: any = [];
  @ViewChild('dataproviderRef') dataproviderchildForm;
  dataCategory: any;
  constructor(public dialog: MatDialog, private ApiService: ApiService, private activeRoute: ActivatedRoute,
    private frmBuilder: FormBuilder, private router: Router) {
    this.activeRoute.params.subscribe(queryParams => {
      this.providerId = queryParams.providerId;
    });
  }
  ngOnInit() {
    this.getAllEntity();
    if (this.providerId == undefined) {
      console.log(this.providerId);
      this.getDataProviders();
    }
    // if (this.providerId) {
    //   console.log(this.entityList);

    //   if (this.entityList == 0) {
    //     this.getEntity();
    //   }
    // }
    if (this.providerId == -1) {

      this.dataProvidersForm = this.frmBuilder.group({
        name: [],
        emailaddress: [],
        phone: [],
        dataproviderid: ['', Validators.required],
        entitycode: ['', Validators.required],
        datacategorykey: ['', Validators.required],
        ccemail: ['']
      });
      this.dataCategoryForm = this.frmBuilder.group({
        dataCategoryInfo: this.frmBuilder.array([])
      });
      this.getAdmins();


    }
    else if (this.providerId) {
      this.getDataProviders1(this.providerId);
      this.dataProviderInfoBlock = true;
      this.existingDCEnable = true;
      this.dataProvidersForm = this.frmBuilder.group({
        name: [],
        emailaddress: [],
        phone: [],
        dataproviderid: [this.providerId],
        entitycode: ['', Validators.required],
        datacategorykey: ['', Validators.required],
        ccemail: ['']
      });
      this.dataCategoryForm = this.frmBuilder.group({
        dataCategoryInfo: this.frmBuilder.array([
          this.frmBuilder.group({
            entitycode: [''],
            datacategorykey: [''],
            ccemail: [''],
            id: [''],
            dataproviderid: ['']
          })
        ])
      });
      this.getAdmins();
      //this.getEntity();            
    }
  }

  ngAfterViewInit() {	
  }

  filter(val) {
    this.filteredOptions = this.adminList.filter((s) => new RegExp(val, 'gi').test(s.name));
  }
  get dataproviderid() { return this.dataProvidersForm.get('dataproviderid'); }
  get entitycode() { return this.dataProvidersForm.get('entitycode'); }
  get datacategorykey() { return this.dataProvidersForm.get('datacategorykey'); }
  get ccemail() { return this.dataProvidersForm.get('ccemail'); }

  applyFilter(filterValue: string, type: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  getAdmins() {
    this.ApiService.getAllAdminsDetails().subscribe(
      data => {
        this.adminList = data.Admins;
        this.filteredOptions = data.Admins;
        if (this.providerId && this.providerId != -1) {
          this.dataProviderInfo = this.ApiService.getAdminInfo(this.adminList, this.providerId);
        }
      },
      error => {

      });
  }
  getEntity() {
    this.ApiService.getEntityDataCategory().subscribe(result => {
      this.entityList = result.entity;
      this.dataCategoryList = result.dataCategory;
      if (this.providerId && this.providerId != -1) {
        this.getExistingDataCategory(this.dataProviderInfo.id);
      }
    },
      error => { });
  }
  selectAdmin(event) {

    this.datactrl.setValue(event.source.value);
    this.getDataProviders1(this.datactrl.value);
    this.dataProviderInfoBlock = true;
    this.existingDCEnable = true;
    this.dataProviderInfo = this.ApiService.getAdminInfo(this.adminList, this.datactrl.value);
    this.dataproviderid.setValue(this.dataProviderInfo.name);
    this.getExistingDataCategory(this.dataProviderInfo.id);
  }
  // onSelectEntity(event) {
  //   this.dataCategoryList = this.ApiService.getObjects(this.dataCategoryList, event.value);
  // }



  getDataProviders() {
    this.ApiService.getDataProviders().subscribe(
      data => {
        this.dataarray = data.Dataproviders[0];
        const datProviderArray = [];
        console.log("data", this.dataarray);
        let duplicateArr = [], entitydatacategory = []; // duplicate to segregate
        for (let i = 0; i < this.dataarray.length; i++) {
          if (duplicateArr.indexOf(this.dataarray[i].DATAPROVIDER_ID) == -1) {
            duplicateArr.push(this.dataarray[i].DATAPROVIDER_ID);
            const entity = {
              "ENTITYCODE": this.dataarray[i].ENTITYCODE,
              "EntityShort": this.dataarray[i].EntityShort,
              "EntityLong": this.dataarray[i].EntityLong,
              "entity":this.dataarray[i].EntityShort +' - '+this.dataarray[i].EntityLong,
              "DATACATEGORYKEY": this.dataarray[i].DATACATEGORYKEY,
              "datacategory": this.dataarray[i].DataCategory,
              "ccemail": this.dataarray[i].CC_EMAIL
            }
            entitydatacategory.push(entity);
            for (let j = i; j < this.dataarray.length; j++) {
              if (i != j) {
                if (this.dataarray[i].DATAPROVIDER_ID == this.dataarray[j].DATAPROVIDER_ID) {
                  const entityinside = {
                    "ENTITYCODE": this.dataarray[j].ENTITYCODE,
                    "EntityShort": this.dataarray[j].EntityShort,
                    "EntityLong": this.dataarray[j].EntityLong,
                    "entity":this.dataarray[j].EntityShort +' - '+this.dataarray[j].EntityLong,
                    "DATACATEGORYKEY": this.dataarray[j].DATACATEGORYKEY,
                    "datacategory": this.dataarray[j].DataCategory,
                    "ccemail": this.dataarray[j].CC_EMAIL
                  }
                  entitydatacategory.push(entityinside);
                }
              }
            }
            console.log('entitydatacategory', entitydatacategory);
            const maindata = {
              "dataproviderid": this.dataarray[i].DATAPROVIDER_ID,
              "name": this.dataarray[i].DATAPROVIDER_NAME,
              "emailaddress": this.dataarray[i].EMAILADDRESS,
              "phone": this.dataarray[i].PHONE,
              "entitydatacategory": entitydatacategory
            }
            console.log('maindata', maindata);
            datProviderArray.push(maindata)
            entitydatacategory = [];
          }
        }
        console.log("array", datProviderArray);

        this.dataSource.data = datProviderArray;
        this.dataLength = datProviderArray.length;
        this.pageLength = Math.ceil(this.dataLength/ this.pageSize);
        this.dataSource.sort = this.sort;
        setTimeout(() => this.dataSource.paginator = this.paginator);
      }, error => {

      });
  }
  getDataProviders1(id) {
    console.log("inside 1");
    const body = {
      "dataproviderId": id
    }
    this.ApiService.getDataProviders1(body).subscribe(
      data => {
        this.dataSource.data = data.Dataproviders;
       // console.log(data.Dataproviders[0].entitydatacategory);
        //this.entitydatacategory = data.Dataproviders[0].entitydatacategory;
        this.dataLength = data.Dataproviders.length;
        this.pageLength = Math.ceil(this.dataLength / this.pageSize);
        this.dataSource.sort = this.sort;
        setTimeout(() => this.dataSource.paginator = this.paginator);
      }, error => {

      });
      // console.log(this.dataSource.data["entitydatacategory"])
  }
  editProvider(item) {
    this.router.navigate([manageProviders, item.dataproviderid, 1016]);
  }
  addProvider() {
    this.router.navigate([manageProviders, -1, 1016]);
  }
  removeProviderDialog(item) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: "REMOVE PROVIDER",
      title: "REMOVE PROVIDER",
      page: "remove-provider",
      providerId: item.dataproviderid
    };
    const dialogRef = this.dialog.open(DialogComponent, { data: { dialogConfig, component: AdminDialogComponent }, width: '850px' });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.getDataProviders();
        }
      });
  }
  removeDataCategoryDialog(item) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: "REMOVE DATA CATEGORY",
      title: "REMOVE DATA CATEGORY",
      page: "remove-assigned-category",
      categoryId: this.dataCategoryForm.value.dataCategoryInfo[item].id
    };
    const dialogRef = this.dialog.open(DialogComponent, { data: { dialogConfig, component: AdminDialogComponent }, width: '850px' });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.successBlock = true;
          this.successMsg = '<strong>Removed Successfully</strong>';
          this.getExistingDataCategory(this.dataProviderInfo.id);
          this.toggleMessage('success');
        }
      });
  }
  saveDatacategory(item) {
    console.log(item);
    console.log(this.dataCategoryForm.value.dataCategoryInfo[item]);
    this.ApiService.updateDataproviderCategory(this.dataCategoryForm.value.dataCategoryInfo[item])
      .subscribe(result => {
        if (!result.error) {
          this.successBlock = true;
          this.successMsg = '<strong>Updated Successfully</strong>';
          this.getExistingDataCategory(this.dataProviderInfo.id);
          this.toggleMessage('success');
        } else {
          this.errorBlock = true;
          this.errorMsg = result.message;
          this.toggleMessage('error');
        }
      },
        error => {
          this.errorDisplay(error);
        });
  }
  onSubmitDataProviders() {
    if (!this.dataProvidersForm.valid) {
      return;
    }
    this.dataProvidersForm.patchValue({
      name: this.dataProviderInfo.name,
      emailaddress: this.dataProviderInfo.emailaddress,
      phone: this.dataProviderInfo.phone
    });
    this.ApiService.addDataProvider(this.dataProvidersForm.value)
      .subscribe(result => {
        if (!result.error) {
          this.successBlock = true;
          this.successMsg = '<strong>Addedd Successfully</strong>';
          this.getExistingDataCategory(this.dataProviderInfo.id);
          this.toggleMessage('success');
          this.dataproviderchildForm.resetForm();
        } else {
          this.errorBlock = true;
          this.errorMsg = result.message;
          this.toggleMessage('error');
        }
      },
        error => {
          this.errorDisplay(error)
        });
  }
  
  
  getExistingDataCategory(id) {
    this.ApiService.getExistingDataCategory(id)
      .subscribe(result => {
        this.existingDataCategory.data = result.Dataproviders;
        this.existingDCdataLength = result.Dataproviders.length;
        this.existingDCpageLength = Math.ceil(this.existingDCdataLength / this.existingDCpageSize);
        this.dataCategoryForm = this.frmBuilder.group({
          dataCategoryInfo: this.frmBuilder.array([])
        });
        //this.dataCategoryDropdown = [];
        const control = <FormArray>this.dataCategoryForm.controls['dataCategoryInfo'];
        result.Dataproviders.forEach(item => {
          control.push(this.patchValues(item))
        });
        setTimeout(() => this.existingDataCategory.paginator = this.existingDCpaginator);
      },
        error => {
          this.errorDisplay(error);
        });
        console.log(this.dataProviderInfo);
  }
  // getExistingDataCategory() {
  //       this.existingDataCategory.data = this.entitydatacategory;
  //       this.existingDCdataLength = result.Dataproviders.length;
  //       this.existingDCpageLength = Math.ceil(this.existingDCdataLength / this.existingDCpageSize);
  //       this.dataCategoryForm = this.frmBuilder.group({
  //         dataCategoryInfo: this.frmBuilder.array([])
  //       });
  //       //this.dataCategoryDropdown = [];
  //       const control = <FormArray>this.dataCategoryForm.controls['dataCategoryInfo'];
  //       result.Dataproviders.forEach(item => {
  //         control.push(this.patchValues(item))
  //       });
  //       setTimeout(() => this.existingDataCategory.paginator = this.existingDCpaginator);
  //       console.log(this.dataProviderInfo);
  // }
  patchValues(obj) {
    console.log(obj);
    //this.dataCategoryDropdown.push(this.ApiService.getObjects(this.dataCategoryList, obj.entitycode));
    return this.frmBuilder.group({
      entity : [obj.entity],
      entitycode: [parseInt(obj.entitycode)],
      datacategorykey: [parseInt(obj.datacategorykey)],
      dataCategory: [obj.datacategory],
      ccemail: [obj.ccemail],
      id: [obj.id],
      dataproviderid: [this.dataProviderInfo.id]
    })
  }
  back(type) {
    if (type == 'provider') {
      this.router.navigate([manageProviders, 1016]);
    }
  }
  errorDisplay(error) {
    this.errorBlock = true;
    if (error.status == "500") {
      this.errorMsg = environment.errorMsg500;
    } else if (error.status == "400") {
      this.errorMsg = error.error.Message;
    } else {
      this.errorMsg = environment.errorMsg502;
    }
    this.toggleMessage('error');
  }
  toggleMessage(type) {
    setTimeout(() => {
      if (type == 'success') {
        this.successBlock = false;
      } else {
        this.errorBlock = false;
      }
    }, 5000);
  }

getAllEntity(){
  this.ApiService.getAllEntity().subscribe(
    data => {
      console.log("data is",data);
    this.entityList = data.entity;
    if(this.providerId && this.providerId != -1) {
      this.getExistingDataCategory(this.providerId);
    }  
      console.log(this.entityList);
    },
      error => {
   });
  }
  onSelectEntity(event){
    const x = event.value;
    const body = {
      "entityCode" : x
    };
        this.dataCategory = this.ApiService.getDataCategory(body).subscribe(result=>{
      // console.log(result);
     this.dataCategory=result.dataCategory[0];
            this.dataCategoryList = this.dataCategory;
     },
      error=>{
     });;
   }
}
/**  Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
