import { Component, OnInit,AfterViewInit,ViewEncapsulation,ViewChild} from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import {ErrorStateMatcher} from '@angular/material/core';
import { FormControl,FormGroup, FormBuilder, Validators,FormGroupDirective, NgForm } from '@angular/forms';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog'; 
import { ApiService } from '../../services/api.service';
import { DialogComponent } from '../../dialog.component';
import { AdminDialogComponent } from '../../adminDialog.component';
import { entityDataService } from '../../services/entityData.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-manageCategory',
  templateUrl: './manageCategory.component.html',
  styleUrls: ['./manageCategory.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ManageCategoryComponent implements OnInit,AfterViewInit{	
  CategoryId;
  matcher = new MyErrorStateMatcher();
  categoryForm: FormGroup;
  entityList: any[];    
  dataCategoryList:any = [];    
  dataCategory:any;
  categoryObj:any;
  currentUser:any;
  successBlock:boolean = false;
  successMsg: string;
  errorBlock:boolean = false;
  errorMsg: string;
  @ViewChild('addCategoryRef') addCategoryForm;
  constructor(public dialog: MatDialog,private ApiService: ApiService,private route: ActivatedRoute,
    private frmBuilder:FormBuilder, private router: Router,private entityDataService:entityDataService) {
      this.CategoryId = this.route.snapshot.params.CategoryId;
      this.route.params.subscribe( params => {
        this.CategoryId = params.CategoryId;});
      this.categoryObj = this.entityDataService.categoryObj;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));       
  }
  ngOnInit() { 
  if(this.CategoryId == 0) {
    this.categoryForm = this.frmBuilder.group({
			entityId:[null,Validators.required],
			dataCategoryId:[null,Validators.required],
      inputMethod:[null,Validators.required],
      reportingFrequencyIndicator:[null,Validators.required],
      reportProductionEmployee:[],
      statusNotes:[null,Validators.required],
      name:[this.currentUser.firstName+' '+this.currentUser.lastName],
      email:[this.currentUser.emailAddress]

    });
    this.getDatacategory();
    this.getEntityList();
  } else {
    this.categoryForm = this.frmBuilder.group({
			entityId:[],
			dataCategoryId:[],
      inputMethod:[],
      reportingFrequencyIndicator:[],
      reportProductionEmployee:[],
      statusNotes:[],
      name:[this.currentUser.firstName+' '+this.currentUser.lastName],
      email:[this.currentUser.emailAddress]
    });
    if(this.categoryObj) {
      this.categoryForm.patchValue({
        entityId:this.categoryObj.EntityCode,
        dataCategoryId:this.categoryObj.DataCategoryKey,
        reportProductionEmployee:this.categoryObj.Report_Production_Employee,
        statusNotes:this.categoryObj.statusNotes
      });      
    } else {
      this.router.navigate(['databook/manageCategory',0,1019]);
    }
  } 

  } 

  ngAfterViewInit() {	
  }
  
  getDatacategory() {
    this.ApiService.getDataCategoryDropdown().subscribe( result => {		     			
      this.dataCategoryList = result.Data;
      },
      error => {});
  } 
  getEntityList() {
    this.ApiService.getEntityList().subscribe( result => {
      this.entityList = result.entity;
      },
      error => {});
  } 
  onSubmitCategory(){
    if(!this.categoryForm.valid) {
      return false;
    }
    
    if(this.CategoryId == 0) {
      this.ApiService.addDataCategory(this.categoryForm.value)
	     .subscribe( result => {
        if(result.error) {
          this.errorBlock = true;
          this.errorMsg = result.message;
          this.toggleMessage('error');
        } else {
        this.addCategoryForm.resetForm();
        this.successBlock = true;
        this.successMsg = result.Success;
        this.toggleMessage('success');
        }
			},
      error => {
        this.errorDisplay(error);								
			});
    } else {
      this.ApiService.editDataCategory(this.categoryForm.value)
      .subscribe( result => {
       this.successBlock = true;
       this.successMsg = result.Success;
       this.toggleMessage('success');
     },
     error => {
       this.errorDisplay(error);								
     });
    }
  } 
  openDeactivateDialog(status) {
    let msg = 'ACTIVATE';
    let title = 'ACTIVATE';
    if(status == 'A') {
      msg = 'DEACTIVATE';
      title = 'DEACTIVATE';
    }
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: msg,
			title: title,
      page:'deactivate-category',
      categoryId:this.CategoryId,
      status:status,
      obj:this.categoryObj
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.router.navigate(['databook/admin',1010]);
				}				
		}); 
  }
  
  back(type){
    if(type == 'admin') {
     this.router.navigate(['databook/admin',1010]);
    }
  } 
  reset(){
    this.addCategoryForm.resetForm();
  }
  errorDisplay(error) {
    this.errorBlock = true;
    if(error.status == "500"){
      this.errorMsg = environment.errorMsg500;
    } else if(error.status == "400"){
      this.errorMsg = error.error.Error;
    } else {
      this.errorMsg = environment.errorMsg502;
    }
    this.toggleMessage('error');
  }
  toggleMessage(type){
    setTimeout(() => {
     if(type == 'success'){
      this.successBlock = false;
     } else {
      this.errorBlock = false;
     }      
  }, 5000);
  }
}
/** Error when invalid control is dirty, touched, or submitted.*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted; 
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}